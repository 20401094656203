body{
	margin:0px;
	padding:0px;
	font-family: 'Raleway', sans-serif;
	box-sizing:border-box;
}

*{
	margin:0px;
	padding:0px;
	box-sizing:border-box;
}

img{
	max-width:100%;
}

h1,h2,h3,h4,h5,h6{
	margin:0px;
}

ul,ol{
	margin:0px;
	padding:0px;
}

li{
	list-style:none;
}

a{
	text-decoration:none;
}

p{
	margin:0px;
}

a:hover{
	text-decoration:none;
}

button:focus{
	outline:none;
}

.container {
	max-width: 1170px;
	
	@media screen and (max-width: 575px){
		padding: 0px 15px;
		max-width: 100%;
		width: 100%;
	}
}


