@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;600;700;800&display=swap);
/* ================================ Basic CSS =============================== */
body {
  margin: 0px;
  padding: 0px;
  font-family: 'Raleway', sans-serif;
  box-sizing: border-box; }

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box; }

img {
  max-width: 100%; }

h1, h2, h3, h4, h5, h6 {
  margin: 0px; }

ul, ol {
  margin: 0px;
  padding: 0px; }

li {
  list-style: none; }

a {
  text-decoration: none; }

p {
  margin: 0px; }

a:hover {
  text-decoration: none; }

button:focus {
  outline: none; }

.container {
  max-width: 1170px; }
  @media screen and (max-width: 575px) {
    .container {
      padding: 0px 15px;
      max-width: 100%;
      width: 100%; } }

/* ================================ Header CSS =============================== */
header .my-nav {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  padding: 10px 0px;
  border: 1px solid rgba(255, 255, 255, 0.1); }
header .sub-01 {
  position: relative;
  background: #002db3; }
header .nav-items {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  max-width: 100%;
  width: 100%;
  position: relative; }
  header .nav-items .menu-toggle {
    position: absolute;
    top: 1.5625rem;
    right: 0.9375rem;
    transition: ease-in-out 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0; }
    @media screen and (max-width: 767.98px) {
      header .nav-items .menu-toggle {
        visibility: visible;
        opacity: 1; } }
    header .nav-items .menu-toggle .menu-hamburger {
      width: 1.875rem;
      height: 0.125rem;
      transition: ease-in-out 0.1s;
      box-shadow: 0 2px 5px rgba(255, 255, 255, 0.6);
      background: #fff;
      position: relative; }
      header .nav-items .menu-toggle .menu-hamburger.active {
        background: rgba(0, 0, 0, 0);
        box-shadow: none; }
        header .nav-items .menu-toggle .menu-hamburger.active::after {
          transform: rotate(45deg); }
        header .nav-items .menu-toggle .menu-hamburger.active::before {
          transform: rotate(135deg); }
      header .nav-items .menu-toggle .menu-hamburger::before {
        position: absolute;
        content: "";
        width: 1.875rem;
        height: 0.125rem;
        transition: ease-in-out 0.1s;
        box-shadow: 0 2px 5px rgba(255, 255, 255, 0.6);
        background: #fff;
        transform: translatey(-0.625rem); }
      header .nav-items .menu-toggle .menu-hamburger::after {
        position: absolute;
        content: "";
        width: 1.875rem;
        height: 0.125rem;
        transition: ease-in-out 0.1s;
        box-shadow: 0 2px 5px rgba(255, 255, 255, 0.6);
        background: #fff;
        transform: translatey(0.625rem); }
  header .nav-items .logo {
    width: 30%;
    flex: 1 1 30%;
    max-width: 30%; }
    @media screen and (max-width: 767.98px) {
      header .nav-items .logo {
        width: 80%;
        flex: 1 1 80%;
        max-width: 80%; } }
  header .nav-items .menu-items {
    width: 70%;
    flex: 1 1 70%;
    max-width: 70%;
    position: relative;
    align-items: center;
    justify-content: flex-end; }
    @media screen and (max-width: 767.98px) {
      header .nav-items .menu-items {
        width: 100%;
        flex: 1 1 100%;
        max-width: 100%; } }
    header .nav-items .menu-items .menu {
      display: block;
      width: 100%;
      text-align: right; }
      header .nav-items .menu-items .menu.active {
        opacity: 1;
        visibility: visible;
        display: block;
        transition: ease-in-out 0.9s; }
      @media screen and (max-width: 767.98px) {
        header .nav-items .menu-items .menu {
          text-align: left;
          opacity: 0;
          visibility: hidden;
          display: none; } }
      header .nav-items .menu-items .menu ul li {
        display: inline-block;
        padding: 0.625rem; }
        @media screen and (max-width: 767.98px) {
          header .nav-items .menu-items .menu ul li {
            display: block; } }
        header .nav-items .menu-items .menu ul li a {
          color: #fff;
          padding: 0 1.25rem;
          font-weight: 700; }
          @media screen and (max-width: 767.98px) {
            header .nav-items .menu-items .menu ul li a {
              padding: 0rem; } }
          @media (min-width: 768px) and (max-width: 991.98px) {
            header .nav-items .menu-items .menu ul li a {
              padding: 0 0.625rem; } }

.og-hf {
  position: fixed;
  transition: ease-in-out 0.5s;
  width: 100%;
  z-index: 999;
  left: 0;
  top: 0; }
  .og-hf .my-nav {
    background: #002db3; }

/* ================================ Slider CSS =============================== */
.slider {
  position: relative;
  padding: 7.5rem 0;
  overflow: hidden;
  background: linear-gradient(to right, #002db3, #0033CC);
  z-index: 1; }
  .slider::before {
    border-right: 50vw solid #fff;
    border-top: 6.25rem solid transparent;
    bottom: 0;
    content: "";
    height: 0;
    position: absolute;
    right: 0;
    width: 0;
    z-index: 1; }
  .slider::after {
    border-left: 50vw solid #fff;
    border-top: 6.25rem solid transparent;
    bottom: 0;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    width: 0;
    z-index: 1; }
  .slider .shap {
    position: absolute;
    background-image: linear-gradient(35deg, #fff, #edf4f8);
    width: 45%;
    height: 85%;
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    z-index: 1;
    opacity: 0.1; }
  .slider .shap-01 {
    position: absolute;
    top: 10rem;
    left: 50%;
    opacity: 0.1;
    -webkit-animation-name: rotateMe;
    animation-name: rotateMe;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear; }
  .slider .shap-02 {
    position: absolute;
    left: 6%;
    transform: rotate(54deg);
    z-index: 1;
    opacity: 0.1; }
  .slider .shape-03 {
    position: absolute;
    z-index: 0;
    left: 60%;
    top: -5%;
    width: 31.25rem;
    height: 100%;
    transform: translate3d(-23%, 17%, 0) rotate(-54deg);
    background-image: linear-gradient(35deg, #fff, #edf4f8);
    opacity: 0.1; }
    .slider .shape-03::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 2.5rem;
      background: #fff;
      left: 0px;
      top: -246px;
      z-index: -1;
      border-radius: 1.25rem;
      transform: rotate(79deg); }
    .slider .shape-03::after {
      position: absolute;
      content: "";
      width: 31.25rem;
      height: 31.25rem;
      background: #ffff;
      border-radius: 50%;
      right: -36.125rem;
      top: 9.5625rem; }
  .slider .main-wrapper {
    display: flex;
    -webkit-display: flex;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    margin: 1.25rem 0;
    position: relative;
    z-index: 2; }
    .slider .main-wrapper .sub-wrapper-01 {
      flex: 1 1 50%;
      max-width: 50%;
      width: 50%; }
      @media screen and (max-width: 767.98px) {
        .slider .main-wrapper .sub-wrapper-01 {
          flex: 1 1 100%;
          max-width: 100%;
          width: 100%; } }
      .slider .main-wrapper .sub-wrapper-01 .image {
        padding: 0.375rem 0.9375rem;
        display: inline-block;
        position: relative;
        z-index: 9; }
      .slider .main-wrapper .sub-wrapper-01 .content {
        padding: 0.625rem 0.9375rem;
        color: #fff;
        display: inline-block;
        position: relative;
        z-index: 9; }
        .slider .main-wrapper .sub-wrapper-01 .content h1 {
          display: block;
          font-size: 2.5rem;
          font-weight: 800;
          line-height: 3.75rem;
          letter-spacing: 0.0625rem;
          text-transform: uppercase;
          margin: 0 0 1.25rem;
          position: relative; }
          .slider .main-wrapper .sub-wrapper-01 .content h1::before {
            position: absolute;
            content: "";
            background-image: radial-gradient(#fafafa 3px, transparent 0);
            background-size: 0.9375rem 0.9375rem;
            height: 9.375rem;
            width: 9.375rem;
            opacity: 0.2;
            right: 7.5rem; }
        .slider .main-wrapper .sub-wrapper-01 .content p {
          font-size: 1.125rem;
          line-height: 1.875rem;
          font-weight: 400;
          margin: 0 0 1.125rem;
          display: inline-block; }
        .slider .main-wrapper .sub-wrapper-01 .content .btn-01 {
          display: block;
          margin: 1.125rem 0;
          position: relative; }
          .slider .main-wrapper .sub-wrapper-01 .content .btn-01 ul li {
            display: inline-block;
            margin: 0 0.625rem;
            padding: 0.3125rem 1.25rem;
            border-radius: 1.25rem;
            background: transparent;
            border: solid 0.0625rem #fff;
            position: relative;
            z-index: 9; }
            .slider .main-wrapper .sub-wrapper-01 .content .btn-01 ul li:nth-of-type(1) {
              margin-left: 0; }
            .slider .main-wrapper .sub-wrapper-01 .content .btn-01 ul li a {
              color: #fff; }

@keyframes rotateMe {
  0% {
    transform: rotate(0deg); }
  to {
    transform: rotate(1turn); } }
/* ================================ Section CSS =============================== */
.bg-se-01 {
  padding: 3.125rem 0;
  position: relative;
  overflow: hidden; }
  .bg-se-01::before {
    position: absolute;
    content: "";
    background: #e8f6ff;
    height: 43.75rem;
    width: 43.75rem;
    left: -10%;
    bottom: -10%;
    transform: translate3d(-21%, -7%, 0) rotate(-54deg); }
    @media screen and (max-width: 767.98px) {
      .bg-se-01::before {
        height: 33.75rem;
        width: 33.75rem; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .bg-se-01::before {
        top: 0;
        left: -38%; } }
  .bg-se-01 .main-card {
    position: relative;
    display: inline-block;
    margin: 1.875rem 0; }
  .bg-se-01 .content {
    position: relative;
    display: block;
    width: 100%;
    padding: 1rem 1.25rem; }
    .bg-se-01 .content ol li {
      display: inline-block;
      padding: 0 0 0 4.375rem;
      margin: 1.25rem 0;
      z-index: 3;
      position: relative; }
      .bg-se-01 .content ol li:nth-child(4)::after {
        display: none; }
      .bg-se-01 .content ol li:nth-child(4)::before {
        content: "\f5ea"; }
      .bg-se-01 .content ol li:nth-child(2)::before {
        content: "\f155"; }
      .bg-se-01 .content ol li:nth-child(3)::before {
        content: "\f073"; }
      .bg-se-01 .content ol li::before {
        position: absolute;
        content: "\f19c";
        font-family: "Font Awesome 5 Pro";
        font-size: 1.25rem;
        width: 3.125rem;
        height: 3.125rem;
        background: #002db3;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        border-radius: 50%;
        z-index: 2;
        box-shadow: 0 0 0.0625rem 0.375rem #dfe7ff; }
      .bg-se-01 .content ol li::after {
        position: absolute;
        content: "";
        width: 0.1875rem;
        height: 100%;
        top: 2.5rem;
        left: 1.5625rem;
        background: #dfe7ff;
        z-index: 1; }
      .bg-se-01 .content ol li h3 {
        font-size: 1.25rem;
        font-weight: 700;
        color: #333333;
        text-transform: capitalize;
        margin: 0 0 0.625rem 0; }
      .bg-se-01 .content ol li p {
        font-size: 1rem;
        color: #848484;
        line-height: 1.5rem;
        display: inline-block;
        margin: 0 0 0.625rem 0;
        font-weight: 400; }

.heading {
  position: relative;
  margin: 1.25rem 0;
  display: block;
  padding: 0 0.9375rem; }
  .heading h2 {
    font-size: 1.5625rem;
    position: relative;
    padding: 0 0 0 1.25rem;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
    font-weight: 800;
    color: #333333; }
    .heading h2::before {
      position: absolute;
      content: "";
      width: 5%;
      height: 100%;
      bottom: 0;
      left: 0;
      background: #002db3; }

.bg-se-02 {
  padding: 3.125rem 0;
  position: relative;
  background: #f8faff;
  overflow: hidden; }
  .bg-se-02 .main-wrapper {
    position: relative;
    border: solid 0.0625rem #002db3;
    padding: 0.625rem 0;
    background: #fff;
    margin: 1.25rem 0; }
    .bg-se-02 .main-wrapper .content {
      padding: 0.625rem 1.25rem;
      position: relative; }
      .bg-se-02 .main-wrapper .content .icon {
        height: 3.75rem;
        width: 3.75rem;
        line-height: 3.75rem;
        background: #f8faff;
        margin: 0 auto;
        border-radius: 0.1875rem;
        color: #002db3;
        font-size: 1.875rem;
        border-radius: 50%; }
      .bg-se-02 .main-wrapper .content .sentence {
        position: relative;
        margin: 1.25rem 0 0;
        display: inline-block; }
        .bg-se-02 .main-wrapper .content .sentence h3 {
          font-size: 1.25rem;
          font-weight: 700;
          margin: 0 0 1.25rem; }
        .bg-se-02 .main-wrapper .content .sentence p {
          font-size: 1rem;
          color: #656565;
          line-height: 1.5625rem; }

.bg-se-03 {
  padding: 3.125rem 0;
  position: relative;
  overflow: hidden; }
  .bg-se-03 .wrapper {
    margin: 1.25rem 0;
    position: relative; }
    .bg-se-03 .wrapper .owl-nav {
      position: relative;
      display: inline-block;
      width: 100%; }
      .bg-se-03 .wrapper .owl-nav .owl-prev {
        position: absolute;
        height: 2.5rem;
        width: 2.5rem;
        background: #002db3;
        line-height: 2.1875rem;
        left: 45%;
        border-radius: 0.3125rem; }
        @media screen and (max-width: 575.98px) {
          .bg-se-03 .wrapper .owl-nav .owl-prev {
            left: 35%; } }
        @media (min-width: 576px) and (max-width: 767.98px) {
          .bg-se-03 .wrapper .owl-nav .owl-prev {
            left: 40%; } }
        .bg-se-03 .wrapper .owl-nav .owl-prev span {
          font-size: 2.5rem;
          color: #fff;
          position: relative;
          display: inline-block; }
      .bg-se-03 .wrapper .owl-nav .owl-next {
        position: absolute;
        height: 2.5rem;
        width: 2.5rem;
        background: #002db3;
        text-align: center;
        line-height: 2.1875rem;
        left: 50%;
        border-radius: 0.3125rem; }
        .bg-se-03 .wrapper .owl-nav .owl-next span {
          font-size: 2.5rem;
          color: #fff;
          position: relative;
          display: inline-block; }
    .bg-se-03 .wrapper .item .my-items {
      padding: 0.625rem 1.25rem;
      position: relative;
      display: block; }
      .bg-se-03 .wrapper .item .my-items .content .image-01 {
        display: block;
        width: 100%; }
      .bg-se-03 .wrapper .item .my-items .content .sentence {
        padding: 0.625rem 0;
        text-align: center;
        display: inline-block;
        border: 0.0625rem solid #dddd;
        border-top: 0rem;
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem; }
        .bg-se-03 .wrapper .item .my-items .content .sentence b {
          font-size: 1.25rem;
          display: inline-block;
          margin: 0 0 0.625rem;
          color: #002db3; }
        .bg-se-03 .wrapper .item .my-items .content .sentence p {
          font-size: 0.9375rem;
          margin: 0 0 0.625rem;
          color: #444444; }
        .bg-se-03 .wrapper .item .my-items .content .sentence ol li {
          display: inline-block;
          font-size: 0.75rem;
          color: #002db3;
          padding: 0 0.625rem 0 0;
          border-right: 0.0625rem solid #dddd;
          margin-bottom: 0.625rem; }
          .bg-se-03 .wrapper .item .my-items .content .sentence ol li:nth-child(3) {
            border: 0; }

.bg-se-04 {
  padding: 3.125rem 0;
  background-image: url(../images/background/1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 9; }
  .bg-se-04::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 45, 179, 0.9);
    z-index: 1; }
  .bg-se-04 ._lk_bg_cd {
    padding: 1.25rem 1.5625rem;
    position: relative;
    display: block;
    z-index: 8;
    text-align: center; }
    .bg-se-04 ._lk_bg_cd i {
      font-size: 1.5625rem;
      color: #002db3;
      background: #fff;
      height: 3.75rem;
      width: 3.75rem;
      line-height: 3.75rem;
      border-radius: 50%;
      display: block;
      margin: 0 auto 1.25rem;
      font-weight: bold; }
    .bg-se-04 ._lk_bg_cd .counting {
      display: block;
      font-size: 1.25rem;
      color: #fff;
      font-weight: 800; }
    .bg-se-04 ._lk_bg_cd h5 {
      font-size: 1.125rem;
      color: #fff;
      padding: 0.625rem 0;
      display: inline-block; }

.bg-se-05 {
  padding: 3.125rem 0;
  position: relative;
  overflow: hidden; }
  .bg-se-05 ._lk_bg_sd_we {
    position: relative;
    overflow: hidden;
    margin: 1.25rem 0;
    box-shadow: 0px 0px 15px 0px #ddd; }
    .bg-se-05 ._lk_bg_sd_we ._xs_we_er {
      display: inline-block;
      width: 100%;
      padding: 0.9375rem; }
      .bg-se-05 ._lk_bg_sd_we ._xs_we_er ._he_w {
        position: relative;
        margin: 0.625rem 0;
        z-index: 2;
        overflow: hidden; }
        .bg-se-05 ._lk_bg_sd_we ._xs_we_er ._he_w p {
          font-size: 1rem;
          color: #5c5c5c;
          letter-spacing: 0.5px;
          line-height: 1.5rem;
          margin: 0.9375rem 0 0.625rem 0; }
        .bg-se-05 ._lk_bg_sd_we ._xs_we_er ._he_w ol li {
          color: #002db3;
          font-size: 0.875rem;
          display: inline-block;
          width: 100%;
          margin: 0.3125rem 0 0; }
          .bg-se-05 ._lk_bg_sd_we ._xs_we_er ._he_w ol li ._mn_cd_xs {
            margin: 0 0.625rem; }
          .bg-se-05 ._lk_bg_sd_we ._xs_we_er ._he_w ol li span {
            color: #5c5c5c;
            font-size: 0.9375rem;
            font-weight: 500;
            margin: 0 0.3125rem 0 0; }
        .bg-se-05 ._lk_bg_sd_we ._xs_we_er ._he_w h3 {
          font-size: 1.125rem;
          font-weight: 400;
          color: #292929; }
    .bg-se-05 ._lk_bg_sd_we ._bv_xs_we {
      position: relative;
      z-index: 1; }
      .bg-se-05 ._lk_bg_sd_we ._bv_xs_we:hover::before {
        opacity: 0.8;
        height: 100%;
        visibility: visible;
        transition: 0.5s; }
      .bg-se-05 ._lk_bg_sd_we ._bv_xs_we::before {
        content: "";
        position: absolute;
        height: 0;
        width: 100%;
        top: 0;
        background: #002db3;
        transition: 0.5s;
        opacity: 0;
        visibility: hidden; }

.bg-se-06 {
  padding: 3.125rem 0;
  position: relative;
  overflow: hidden;
  background: #fafafa; }
  .bg-se-06 .team-card {
    position: relative;
    display: block;
    margin: 1.25rem 0; }
    .bg-se-06 .team-card .image-team {
      display: inline-block; }
    .bg-se-06 .team-card .team-content {
      display: block;
      text-align: center;
      border: solid #002db3 0.0625rem;
      border-top: 0rem;
      background: #fff; }
      .bg-se-06 .team-card .team-content h3 {
        font-size: 1.125rem;
        font-weight: 700;
        color: #002db3;
        padding: 0.625rem 0 0.3125rem 0; }
      .bg-se-06 .team-card .team-content p {
        display: block;
        font-size: 0.9375rem;
        color: #848484;
        padding: 0 0 0.625rem 0; }
      .bg-se-06 .team-card .team-content ol li {
        display: inline-block;
        height: 1.875rem;
        width: 1.875rem;
        line-height: 1.875rem;
        text-align: center;
        background: #002db3;
        color: #fff;
        transition: ease-in-out 0.5s;
        padding: 0 0.3125rem;
        position: relative; }
        .bg-se-06 .team-card .team-content ol li:hover {
          background: #002db3; }

.abt-01 {
  padding: 3.125rem 0;
  background: #002db3;
  position: relative;
  overflow: hidden;
  z-index: 9; }
  .abt-01::before {
    position: absolute;
    content: "";
    background: #fff;
    height: 9.375rem;
    width: 9.375rem;
    top: 0%;
    left: 48%;
    z-index: 1;
    opacity: 0.1;
    transform: translate3d(-23%, 17%, 0) rotate(-54deg);
    display: none; }
    @media screen and (max-width: 767px) {
      .abt-01::before {
        display: none; } }
  .abt-01 .heading-wrapper {
    position: relative;
    margin: 1.25rem 0;
    display: block;
    text-align: center;
    z-index: 8; }
    .abt-01 .heading-wrapper h3 {
      font-size: 1.25rem;
      font-weight: 700;
      color: #fff;
      margin-bottom: 0.625rem;
      text-transform: uppercase; }
    .abt-01 .heading-wrapper ol li {
      display: inline-block;
      font-size: 1rem;
      color: #ddd; }
      .abt-01 .heading-wrapper ol li:nth-child(2) {
        color: #fff; }
      .abt-01 .heading-wrapper ol li i {
        margin: 0 0.625rem;
        color: #ddd; }

.abt-02 {
  padding: 3.125rem 0;
  position: relative;
  background: #fafafa;
  overflow: hidden; }
  .abt-02 .abt-main {
    flex-wrap: wrap;
    flex: 1 1 100%;
    padding: 10px 15px;
    margin: 30px 0px; }
    .abt-02 .abt-main .abt-spirit {
      width: 50%;
      max-width: 50%;
      flex: 1 1 50%;
      position: relative;
      padding: 10px 15px; }
      @media (max-width: 575.98px) {
        .abt-02 .abt-main .abt-spirit {
          width: 100%;
          max-width: 100%;
          flex: 1 1 100%; } }
      .abt-02 .abt-main .abt-spirit .abt-content-card {
        margin: 10px 0px;
        position: relative; }
        .abt-02 .abt-main .abt-spirit .abt-content-card span {
          display: block;
          margin: 20px 0px;
          text-align: center; }
          .abt-02 .abt-main .abt-spirit .abt-content-card span a {
            padding: 6px 14px;
            border: 1px solid #fff;
            background: #002db3;
            color: #fff;
            font-size: 15px; }
        .abt-02 .abt-main .abt-spirit .abt-content-card h2 {
          font-size: 25px;
          margin: 0 0 10px 0; }
        .abt-02 .abt-main .abt-spirit .abt-content-card p {
          font-size: 16px;
          line-height: 27px;
          color: #6a6a6a;
          letter-spacing: 0.5px; }
      .abt-02 .abt-main .abt-spirit .image-box {
        position: relative;
        z-index: 1; }
        .abt-02 .abt-main .abt-spirit .image-box::before {
          content: "";
          position: absolute;
          background: #002db3;
          width: 100%;
          height: 100%;
          left: -10px;
          top: -10px;
          z-index: -1; }

.bg-0-b {
  padding: 50px 0px;
  position: relative;
  background: #e8f0f5;
  z-index: 2; }
  .bg-0-b .main-card-contact {
    flex-wrap: wrap;
    width: 100%;
    margin: 20px 0px;
    padding: 10px 15px;
    position: relative;
    z-index: 9; }
    .bg-0-b .main-card-contact .sup-card-contact {
      width: 40%;
      max-width: 40%;
      flex: 1 1 40%; }
      @media screen and (max-width: 767.98px) {
        .bg-0-b .main-card-contact .sup-card-contact {
          width: 100%;
          max-width: 100%;
          flex: 1 1 100%; } }
      .bg-0-b .main-card-contact .sup-card-contact .contact-title {
        position: relative; }
        .bg-0-b .main-card-contact .sup-card-contact .contact-title h2 {
          font-size: 20px;
          margin: 0 0 10px 0;
          color: #222;
          padding-bottom: 5px;
          position: relative;
          display: inline-block; }
          .bg-0-b .main-card-contact .sup-card-contact .contact-title h2::before {
            position: absolute;
            content: "";
            background: #002db3;
            width: 100%;
            height: 3px;
            bottom: 0; }
        .bg-0-b .main-card-contact .sup-card-contact .contact-title ol li {
          display: block;
          padding: 10px 0px;
          color: #606060;
          font-size: 16px;
          font-weight: 600; }
          .bg-0-b .main-card-contact .sup-card-contact .contact-title ol li i {
            color: #002db3;
            padding-right: 15px; }
      .bg-0-b .main-card-contact .sup-card-contact .head-content {
        position: relative;
        display: block; }
        .bg-0-b .main-card-contact .sup-card-contact .head-content h2 {
          font-size: 20px;
          margin: 0 0 10px 0;
          color: #222;
          padding-bottom: 5px;
          position: relative;
          display: inline-block; }
          .bg-0-b .main-card-contact .sup-card-contact .head-content h2::before {
            position: absolute;
            content: "";
            background: #002db3;
            width: 100%;
            height: 3px;
            bottom: 0; }
        .bg-0-b .main-card-contact .sup-card-contact .head-content p {
          font-size: 16px;
          line-height: 27px;
          display: block;
          padding: 20px 0px;
          margin: 0px; }
    .bg-0-b .main-card-contact .sup-card-contact-0a {
      width: 60%;
      max-width: 60%;
      flex: 1 1 60%; }
      @media screen and (max-width: 767.98px) {
        .bg-0-b .main-card-contact .sup-card-contact-0a {
          width: 100%;
          max-width: 100%;
          flex: 1 1 100%; } }
      .bg-0-b .main-card-contact .sup-card-contact-0a .dived {
        width: 100%;
        flex-wrap: wrap; }
        .bg-0-b .main-card-contact .sup-card-contact-0a .dived .ca-ool {
          padding: 0px 10px; }
        .bg-0-b .main-card-contact .sup-card-contact-0a .dived .form-group {
          width: 50%;
          max-width: 50%;
          flex: 1 1 50%; }
          .bg-0-b .main-card-contact .sup-card-contact-0a .dived .form-group .form-sup {
            margin: 10px;
            position: relative; }
            .bg-0-b .main-card-contact .sup-card-contact-0a .dived .form-group .form-sup .cal-01 {
              position: relative; }
            .bg-0-b .main-card-contact .sup-card-contact-0a .dived .form-group .form-sup i {
              position: absolute;
              right: 0px;
              top: 0px;
              height: 45px;
              width: 45px;
              background: #002db3;
              text-align: center;
              line-height: 45px;
              color: #fff;
              border-top-right-radius: .25rem;
              border-bottom-right-radius: .25rem; }
            .bg-0-b .main-card-contact .sup-card-contact-0a .dived .form-group .form-sup label {
              font-size: 15px;
              color: #6e6e6e; }
              .bg-0-b .main-card-contact .sup-card-contact-0a .dived .form-group .form-sup label span {
                position: relative;
                top: 4px;
                left: 5px;
                color: #002db3; }
          .bg-0-b .main-card-contact .sup-card-contact-0a .dived .form-group .form-control {
            padding: 10px 15px;
            margin: 30px 0px;
            height: 45px;
            position: relative; }

/* ================================ Footer CSS =============================== */
footer {
  padding: 3.125rem 0;
  position: relative;
  overflow: hidden;
  background: #12141b; }
  footer ._kl_de_w {
    margin: 1.25rem 0;
    padding: 0.625rem 0;
    color: #fff;
    position: relative; }
    footer ._kl_de_w .form-group a {
      text-align: left;
      padding: 0.625rem 0.9375rem;
      border-radius: 0.25rem;
      background: #fff;
      color: #002db3;
      font-size: 0.9375rem;
      display: inline-block; }
    footer ._kl_de_w ol .last {
      border-bottom: 0.0625rem solid #92929292; }
    footer ._kl_de_w ol li {
      display: block;
      margin: 0 1.5625rem 0 0;
      padding: 0.625rem 0;
      border-top: 0.0625rem solid #92929292;
      color: #ddd;
      font-size: 0.9375rem; }
      @media screen and (max-width: 768px) {
        footer ._kl_de_w ol li {
          margin: 0rem; } }
      footer ._kl_de_w ol li i {
        margin-right: 0.75rem; }
    footer ._kl_de_w p {
      font-size: 0.9375rem;
      line-height: 1.625rem;
      color: #ddd; }
    footer ._kl_de_w h3 {
      font-size: 1.25rem;
      margin: 0 0 1.25rem 0; }

.end-footer {
  padding: 0.625rem 0;
  background: #090a0e;
  position: relative;
  overflow: hidden; }
  .end-footer .last-card {
    display: block;
    width: 100%;
    color: #ddd;
    text-align: center; }
    .end-footer .last-card p {
      font-size: 1rem;
      font-weight: normal;
      display: inline-block;
      padding: 0.625rem 0; }
      .end-footer .last-card p a {
        color: #002db3;
        font-size: 1rem;
        font-weight: 700;
        margin: 0 0 0 0.625rem; }



.logo {
  width: 15% !important;
  flex: 1 1 30%;
  max-width: 15% !important;
}
header .nav-items .menu-items{
  display: flex !important;
}
@media(max-width: 768px){
  .logo {
    width: 27% !important;
  
    max-width: 28% !important;}

    header .nav-items .menu-items{
      background-color: #002fbc;
    }
}
.abt-01{
  padding: 6.125rem 0 !important;
}