#box1 {
  background-color: #002eb6;
  max-width: 1000px;
  
  padding: 0px 40px 20px;
  margin: 50px auto;
}
#box1 form{
  color: white;
}
.form-h1{
  margin: 0px auto;
  max-width: 400px;
  padding: 20px 0px;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"],
select,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid black;
}

fieldset {
  border: 1px solid black;
  background-color: white;
  padding: 10px;
}
.submitButton{
  padding: 7px 20px;
  margin: 20px;
  color: white;
  font-family: Courier, monospace;
  border-radius: 10px;
  background-color: #c2b937;
  border: 0px;
}
fieldset label{
  color: #002eb6;
}

.form-scanner{
  display: flex;
  justify-content: space-between;
}
.form-scanner-1 {
  width: 10%;
}
.form-scanner img{
  width: 100%;
}

@media(max-width:768px){
  .form-scanner-1 {
    width: 20%;
  }
  .form-scanner h3{
    font-size: 18px;
  } 
}