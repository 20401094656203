$color-use:#fff;
$color-main:#002db3;
$color-primary:#848484;
$heading-color:#333333;

@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;600;700;800&display=swap);

@mixin shado-1(){
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

@mixin shado-3 {
    box-shadow: 0 2px 6px 0 rgba(218, 218, 253, 0.65), 0 2px 6px 0 rgba(206, 206, 238, 0.54);
}
@mixin shado-4(){
    box-shadow:  0 2px 3px 0 rgba(218, 218, 253, 0.35), 0 0px 3px 0 rgba(206, 206, 238, 0.35);
}

@mixin shado-5(){
    box-shadow: 0 2px 6px 0 rgba(218, 218, 253, 0.65), 0 2px 6px 0 rgba(206, 206, 238, 0.54);
}

@mixin card-1(){
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius:2px;
}

@mixin shado-6 {
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.10);
}