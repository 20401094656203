
.popup-content{
    width: 70% !important;
}
.popup-overlay {
    position: absolute !important;
}

.popup-content > div {
    overflow: auto !important;
    position: relative !important;
    border-radius: 100px;
}

.popup-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.popup-content p{
    font-size: 12px;
}
.popup-h4{
  
text-align: center;
}
.ph-box1 {
    width: 10%;
}
.ph-box2 h1{
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}
.popup-h4::before{
    content: "";
    width: 150px;
    height: 1px;
    background-color: blue;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.popup-h4::after{
    content: "";
    width: 150px;
    height: 1px;
    background-color: blue;
    display: inline-block;
    vertical-align: middle;
    
}
.popup-box1{
    display: flex;
    justify-content: space-between;
      align-items: center;  
    
}
.pb-btn1{
    background-color: black;
    color: white;
}
.pb-box2child2 {
    display: flex;
    flex-direction: column;
}

.popup-table{
    border: 1px solid black;
    width: 100%;

}
.popup-table td {
    border: 1px solid black;
    
}
.popup-table th{
    border: 1px solid black;
}

@media(max-width:768px){
    .ph-box1 {
        width: 30%;
    }
    .popup-content{
        width: 90% !important;
    }
    .popup-h4::before{
        content: "";
        width: 150px;
        height: 1px;
        background-color: blue;
        display:none;
        vertical-align: middle;
        margin-right: 10px;
    }
    .popup-h4::after{
        content: "";
        width: 150px;
        height: 1px;
        background-color: blue;
        display: none;
        vertical-align: middle;
        
    }
}

.popup-cart{
    padding:20px 50px ;
    max-width: 1300px;
    margin: 20px auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.popup-btn{
    padding: 7px 20px;
    margin: 20px;
    color: white;
    font-family: Courier, monospace;
    border-radius: 10px;
    background-color: #002db3;
    border: 0px;
}

@media(max-width:768px){
    .popup-cart{
        width: 90%;
        margin: 20px auto;
        max-width: 100%;
    }
}
.education-h1{
    max-width: 1000px;
    margin: 40px auto;
    text-align: center;
    color: #002db3;
    font-weight: 800;
    font-size: 60px;
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
    
}
@media(max-width:768px){
    .education-h1{
        width: 60%;
        font-size: 40px;
    }
}

.gallery-img{
    height: 450px;
    width: 100%;
    margin: 20px ;
}
.gallery-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider-img{
    height: 800px;
}
.slider-img img{
    width: 70%;
    
    object-fit: cover;
}
@media(max-width:768px){
    .slider-img{
        height: 400px;
    }
}
.popup-btn a{
    color: white;
}
.popup-btn a:hover{
    color: white;
}

.floating-widgets {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: fixed;
    bottom: 1rem;
    left: 2rem;
    z-index: 999;
}

.floating-widgets i {
    color: white;
}

.whatsapp-widget-after {
    background-color: rgb(35 217 72 / 70%);
    border-radius: 50%;
    padding: 1rem;
    animation: w-pulse-animation 1.5s infinite;
}

.calling-widget-after {
    background-color: rgba(230, 8, 8, 0.7);
    border-radius: 50%;
    padding: 1rem;
    animation: c-pulse-animation 1.5s infinite;
}

.widgets1 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100vh;
    aspect-ratio: 1;
    height: 48px;
    animation: icon-rotate 750ms 500ms infinite;
    transition: -webkit-transform 250ms;
    transition: transform 250ms,
}

.whatsapp-widget {
    background-color: #1cd741;
    font-size: 2em;
}

@-webkit-keyframes w-pulse-animation {
    0% {
        box-shadow: 0 0 0 0px #1cd741aa;
    }

    100% {
        box-shadow: 0 0 0 16px #1cd7411a;
    }
}

@keyframes w-pulse-animation {
    0% {
        box-shadow: 0 0 0 0px #1cd741aa;
    }

    100% {
        box-shadow: 0 0 0 16px #1cd7411a;
    }
}

@-webkit-keyframes c-pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(230, 8, 8, 0.7);
    }

    100% {
        box-shadow: 0 0 0 16px rgba(230, 8, 8, 0.2);
    }
}

@keyframes c-pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(230, 8, 8, 0.7);
    }

    100% {
        box-shadow: 0 0 0 16px rgba(230, 8, 8, 0.2);
    }
}

@-webkit-keyframes icon-rotate {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(-20deg);
    }

    40% {
        transform: rotate(20deg);
    }

    60% {
        -webkit-transform: rotate(-20deg);
        transform: rotate(-20deg);
    }

    80% {
        transform: rotate(20deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.calling-widget {
    background-color: #cd201f;
    font-size: 1.5em;
}

.new-btn{
    position: fixed;
    right:  -14%;
    top: 40%;
    z-index: 1111;
    transform: rotate(-90deg);
    background-color: #002db3 !important;
  }
  .new-btn button{
    background-color: #002db3 !important;
  }
  .new-btn a{
    color:white
  }
  .btn-Download:hover a{
    color: #d6b369;
  }
  @media(max-width: 768px){
    .new-btn{
      right:  -60%;
    }
  }
  .splide img{
    height: 8rem;
  }